



















































import Vue from "vue";
import Requester from "@/requester/index";
import { RecoveryFormData } from "../../../../back/src/types/formData";

import _ from "lodash";
export default Vue.extend({
  name: "UsersRecovery",

  data() {
    return {
      loading: {
        submitForm: false,
      },
      form: {
        email: "",
      },
      rules: {
        required: [(v: string) => !!v || ""],
      },
    };
  },
  computed: {
    formAuthLogin(): Vue & { validate: () => boolean } {
      return this.$refs.formRecovery as Vue & {
        validate: () => boolean;
      };
    },
  },
  methods: {
    submitFormDebounce: _.debounce(async function () {
      await this.submitForm();
    }, 200),

    async submitForm() {
      if (!this.formAuthLogin.validate()) {
        return;
      }
      this.loading.submitForm = true;
      const formData: RecoveryFormData = {
        email: this.form.email,
      };
      try {
        await Requester.users.public.recuperaSenha(formData);
        this.$root.$emit("showToast", {
          text: "E-mail para recuperação enviado com sucesso!",
          color: "success",
        });
        this.$router.push({ name: "Login" });
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
